<template>
  <div class="row">

    <div class="col-12">

      <card>

        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">Newsletter Subscribers List</h4>
          <div class="d-flex justify-content-start">
            <div class="text-center pt-1 px-1">
              <a v-if="$store.getters['auth/checkAccess']('newsletter/export')"
                 class="btn btn-primary btn-wd export_excel" style="display:flex;"
                 @click="openModal('basic')"> Export to excel
                <span class="btn-label px-2">
                  <i class="nc-icon nc-cloud-download-93" style="font-size:20px;"></i>
                  </span>
              </a>

            </div>
          </div>
        </div>

        <div>
          <general-data-table
            ref="table"
            :advanceSearchFilter="advanceSearchFilter"
            :method="'POST'"
            :api-url="'newsletter/index'">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :align="column.align"
                             :sortable="column.sortable"
                             :prop="column.value"
                             :label="column.label">

            </el-table-column>

            <el-table-column
              :min-width="120"
              fixed="right"
              align="center"
              label="Actions">
              <template slot-scope="scope">
                <a v-if="$store.getters['auth/checkAccess']('newsletter/delete')" v-tooltip.top-center="'Delete'"
                   class="btn-danger btn-simple btn-link"
                   @click="openDeleteModal(scope.row.id)"><i class="fa fa-times"></i></a>
              </template>
            </el-table-column>
          </general-data-table>

        </div>


      </card>

      <delete-modal :visible="deleteModalVisibility"
                    :message="'Are you sure you want to delete this Subscriber?'"
                    @close="closeDeleteModal()"
                    @confirm="confirmDeleteModal()">
      </delete-modal>
      <export-modal id="export-modal" title="News Letter" url="newsletter/export"
                    :filters="exportFilters" :modals="modals"></export-modal>
    </div>

  </div>
</template>
<script>
import {TableColumn} from 'element-ui'
import {NewPagination as NLPagination, Switch as LSwitch} from '@/components'
import {DeleteModal as DeleteModal} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";
import ExportModal from '@/components/ExportModal/ExportModal.vue';

export default {
  components: {
    NLPagination,
    DeleteModal,
    LSwitch,
    GeneralDataTable,
    [TableColumn.name]: TableColumn,
    ExportModal
  },

  data() {

    return {
      tableColumns: [
        {label: 'Email', value: 'email', minWidth: '200', align: 'center'},
        {label: 'Name', value: 'name', minWidth: '200', align: 'center'},
        {label: 'Last Name', value: 'name', minWidth: '200', align: 'center'},
        {label: 'Organisation', value: 'organisation', minWidth: '200', align: 'center'},
        {label: 'Date', value: 'created_at', minWidth: '200', align: 'center'},
      ],
      modals: {
        basic: false,
        centered: false,
        custom: false,
        confirm: false
      },
      advanceSearchFilter: [
        {
          key: 'newsletters.created_at',
          type: 'date',
          frontType:'simple',
          strategy: 'gt',
          label: 'from',
          class: 'col-6',
          value: null,
        },
        {
          key: 'newsletters.created_at',
          type: 'date',
          frontType:'simple',
          strategy: 'lt',
          label: 'to',
          class: 'col-6',
          value: null,
        },
        {
          key: 'newsletters.email',
          type: 'text',
          frontType:'simple',
          label: 'Email',
          class: 'col-6',
          value: null,
        },
      ],
      exportFilters: [
        {
          key: 'newsletters.created_at',
          type: 'date',
          frontType:'simple',
          strategy: 'gt',
          label: 'from',
          class: 'col-6',
          value: null,
        },
        {
          key: 'newsletters.created_at',
          type: 'date',
          frontType:'simple',
          strategy: 'lt',
          label: 'to',
          class: 'col-6',
          value: null,
        },
        {
          key: 'newsletters.email',
          type: 'text',
          frontType:'simple',
          label: 'Email',
          class: 'col-6',
          value: null,
        },
      ],
      deleteModalVisibility: false,
      toDeleteId: undefined,
      url : process.env.VUE_APP_SERVER_IMAGE_URL
    }

  },

  methods: {

    openDeleteModal(id) {
      this.deleteModalVisibility = true;
      this.toDeleteId = id;
    },

    closeDeleteModal() {
      this.deleteModalVisibility = false;
    },
    confirmDeleteModal() {

      let data = {
          'id': this.toDeleteId,
      }
      this.axios.delete("newsletter/delete", {headers: {}, data}).then((response) => {

        this.$notify({
          message: "Subscriber deleted successfully",
          timeout: 1000,
          type: 'success'
        });

        this.$refs.table.getData("updateData");

      }).catch((error) => {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      })

      this.closeDeleteModal();
      this.toDeleteId = undefined;
    },
    openModal (name) {
      this.modals[name] = true
    },
    closeModal (name) {
      this.modals[name] = false
    },
  },
}
</script>

